@import '../../../styling/colors';

.login-page {
  .title {
    color: $primary;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    margin-top: 1.4rem;
  }

  .form-container {
    display: block;
  }

  .label {
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 2.8rem;
    margin-bottom: 1rem;
  }
}
