@import '../../../styling/colors';

.irg-button {
  display: block;
  line-height: 1;
  padding: 1rem;
  background-color: $background;
  color: $white;
  border-radius: 0.4rem;
  margin: 2.5rem 0 2rem 0;
  border: none;
  width: 100%;
  font-weight: bold;
  height: 4rem;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;

    &::before {
      content: '';
      position: absolute;
      border: 0.3rem solid $white;
      border-radius: 4rem;
      width: 2rem;
      height: 2rem;
      border-bottom-color: transparent;
      animation: animate-inner 1.4s linear infinite;
    }
  }
}


@keyframes animate-inner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


