@import '../../../styling/colors';

.detail-page {
  .close {
    color: $primary;
    margin-top: 3rem;
    margin-left: 3rem;
    cursor: pointer;
  }

  //remove from collection
  .remove-from-collection-button {
    color: $primary;
    position: absolute;
    top: 3rem;
    right: 14.9rem;
    display: flex;
    cursor: pointer;
  }

  .remove-from-collection-logo {
    padding-right: 0.8rem;
    cursor: pointer;
  }
  .remove-from-collection-text {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  //add to collection
  .add-to-collection-button {
    color: $primary;
    position: absolute;
    top: 3rem;
    right: 20rem;
    display: flex;
    cursor: pointer;
  }

  .add-to-collection-logo {
    padding-right: 0.8rem;
    cursor: pointer;
  }

  .add-to-collection-text {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  //Send
  .send-button {
    color: $primary;
    position: absolute;
    top: 3rem;
    right: 4.2rem;
    display: flex;
    cursor: pointer;
  }

  .send-button-logo {
    padding-right: 0.8rem;
    cursor: pointer;
  }

  .send-button-text {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  //detail project
  .wrapper {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
    padding-bottom: 6.4rem;
    margin: 0 auto;
    font-size: 1.6rem;
    font-weight: 400;
    color: $primary;
  }

  h1 {
    font-size: 6rem;
    font-weight: 500;
    line-height: 7.6rem;
    margin: 0;
    margin-bottom: 2rem;
    margin-top: 2.3rem;
  }

  .page-break {
    page-break-inside: avoid;
  }

  .half-width {
    display: table;
  }

  .half-width > div {
    display: table-cell;
    width: 50%;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin: 2rem 0;
  }

  .quote {
    text-align: center;
    color: $black;
    padding: 0 0 0 12.8rem;
    height: 100%;
    vertical-align: middle;
  }

  .quote_text {
    font-size: 2.4rem;
    line-height: 3rem;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }

  .quote_subtext {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .images {
    margin: 3.2rem 0;
    display: flex;
    height: 43.6rem;
  }

  .images > div {
    box-sizing: border-box;
    width: 50%;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 25rem;
  }

  .images > div:first-child {
    margin-right: 1rem;
  }

  .images > div:last-child {
    margin-left: 1rem;
  }

  .extra-info {
    background: $extra-info-background;
    padding: 3.2rem;
    color: $primary;
  }

  .extra-info h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 3.2rem;
  }

  .extra-info h2:after {
    content: '';
    position: absolute;
    bottom: -1.6rem;
    width: 4rem;
    border: 0.3rem solid $extra-info-dash;
    border-width: 0 0 0.3rem 0;
  }

  .extra-info dl {
    margin: 0;
    width: 100%;
    display: inline-block;
  }

  .extra-info dl.narrow-list {
    display: inline-block;
  }

  .extra-info dl.narrow-list {
    width: 33rem;
    margin-left: 15rem;
    @media only screen and (max-width: 97rem) {
      margin-left: 0;
    }
  }

  .extra-info dl.narrow-list-first {
    width: 32rem;
  }

  .title-medium dt {
    width: 12rem;
    padding-bottom: 0.8rem;
  }

  .title-medium dd {
    width: calc(100% - 12.5rem);
  }

  .title-wide dt {
    width: 16rem;
    padding-bottom: 0.8rem;
  }

  .title-wide dd {
    width: calc(100% - 16.5rem);
  }

  .extra-info dt,
  .extra-info dd {
    line-height: 2.8rem;
    display: inline-block;
    margin: 0;
  }

  .extra-info dd {
    font-weight: bold;
    color: $primary;
  }

  .button-website {
    border: 0.15rem solid $website-button-border;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $white;
    height: 4rem;
    width: 12rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
    cursor: pointer;
  }

  //swiper
  .swiper-container {
    width: 100%;

    height: 50rem;
    .image {
      border-radius: 0.5rem;
      border: transparent;
      height: 43.6rem;
      width: 70.4rem;
      box-shadow: 0 0.3rem 1rem 0 $image-shadow;
    }
  }

  .swiper-slide {
    text-align: center;
    width: auto;
  }

  .swiper-container {
    .image {
      cursor: pointer;
    }
    .swiper-button-prev,
    .swiper-button-next {
      z-index: 2;
      width: 4.8rem;
      height: 4.8rem;
      font-size: 2rem;
      border-radius: 50%;
      background-color: $white;
      color: $primary;
      top: 75rem;
      opacity: 0.8;
      &::after {
        display: block;
        cursor: pointer;
        overflow: hidden;
        font-size: 2rem;
      }
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: pointer;
    }

    .swiper-button-prev {
      left: 2rem;
    }

    .swiper-button-next {
      right: 2rem;
    }
  }

  //swiper-fullscreen
  .swiper-container-fullscreen {
    background: rgba(0, 37, 72, 0.7);
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    .image-fullscreen {
      border-radius: 0.5rem;
      border: transparent;
      height: auto;
      width: auto;
      box-shadow: 0 0.3rem 1rem 0 $image-shadow;
      cursor: pointer;
      margin: auto;
      max-width: 95%;
      max-height: 95%;
    }

    .swiper-slide {
      display: flex;
      padding-bottom: 5rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 4.8rem;
      height: 4.8rem;
      font-size: 2rem;
      border-radius: 50%;
      background-color: $white;
      color: $primary;
      opacity: 0.8;
      top: 95%;
      cursor: pointer;

      &::after {
        display: block;
        cursor: pointer;
        overflow: hidden;
        font-size: 2rem;
      }
    }

    .swiper-button-prev {
      left: 2.5rem;
    }

    .swiper-button-next {
      right: 2.5rem;
    }
  }

  .close-fullscreen {
    position: fixed;
    color: $primary;
    top: 3rem;
    left: 3rem;
    z-index: 3;
    cursor: pointer;
  }
  .swiper-pagination {
    color: $white;
  }

}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}