@import '../../../styling/colors';
@import '../../../styling/fonts';

.irg-error {
    font-size: 1.6rem;
    margin-bottom: 2.9rem;
    color: $red;
    border: 0.1rem solid $red;
    background: $error_background;
    padding: 0.8rem 0.4rem;
}
