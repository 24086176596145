@import '../../../styling/colors';

.mail-format {
  .format-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 95rem;
    min-height: 35rem;
    background-color: $white;
    .checkbox-container {
      flex-direction: column;
      .checkmark {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: 60rem) {
      width: 86rem;
    }
  }

  .format-title {
    padding-top: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.4rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }

  .format-text {
    font-size: 16px;
    line-height: 24px;
    padding-left: 3.2rem;
    padding-right: 3.4rem;
  }

  //table
  .table-projects {
    margin-left: 9rem;
    margin-right: 9rem;
    margin-top: 1.6rem;
    border-spacing: 0px 8px;
    @media only screen and (min-width: 60rem) {
      width: 68rem;
    }
  }

  .project-list {
    max-height: 45rem;
    overflow-y: scroll;
  }

  .project-item {
    background-color: #e4e9f0;
    margin: 0.8rem 0px;
  }

  .project-title {
    padding: 0.9rem;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  //cancel and continue buttons
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .button-format-cancel {
    border: 0.15rem solid $website-button-border;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $white;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .button-format-continue {
    color: $white;
    border-color: $primary;
    background-color: $primary;
    border: none;
    box-sizing: border-box;
    border-radius: 0.4rem;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-left: 0.8rem;
  }

  .checkbox {
    width: 10rem;
  }

  th {
    position: sticky;
    top: 0rem;
    background-color: $white;
    z-index: 2;
  }
}
