@import '../../../styling/colors';

.collection-page {
  //Sidemenu
  .sidemenu {
    .bm-burger-button {
      position: fixed;
      width: 3.6rem;
      height: 3rem;
      left: 3.2rem;
      margin-top: 3.2rem;
    }

    .bm-burger-bars {
      background: $primary;
    }

    .bm-burger-bars-hover {
      background: $primary;
    }

    .bm-cross-button {
      height: 2.4rem;
      width: 2.4rem;
      position: absolute;
      left: 4.1rem;
      margin-top: 3.3rem;
    }

    .bm-cross {
      background: $primary;
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      overflow: hidden;
    }

    .bm-menu {
      background: $primary;
      font-size: 1.6rem;
      padding-top: 7.4rem;
    }

    .bm-item-list {
      color: $white;
      overflow: hidden;
    }

    .bm-overlay {
      background: $white;
    }
  }

  //Header
  .header {
    padding-bottom: 1rem;
    margin: 0;
  }

  .header-container {
    background-color: $white;
    width: 100%;
    height: 9.5rem;
    position: fixed;
    z-index: 2;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 9rem;
    padding-right: 4rem;
    transition: all 0.5s ease 0s;
    &.right-open {
      padding-right: 32rem;
    }
  }

  .icapps-logo {
    cursor: pointer;
  }

  //Send
  .send-button {
    color: $primary;
    position: absolute;
    top: 3.6rem;
    right: 4.2rem;
    display: flex;
    cursor: pointer;
  }

  .send-button-logo {
    padding-right: 0.8rem;
    cursor: pointer;
  }

  .send-button-text {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  //Remove all from collection
  .remove-all-from-collection-button {
    color: $primary;
    position: fixed;
    top: 3.6rem;
    right: 15rem;
    display: flex;
    cursor: pointer;
  }

  .remove-from-collection-logo {
    padding-right: 0.8rem;
    cursor: pointer;
  }

  .remove-from-collection-text {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  .remove-all-title {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    margin-left: 3.2rem;
    margin-top: 3.2rem;
  }

  .remove-all-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-left: 3.2rem;
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }

  //Remove all projects from collection confirmation
  .remove-all-confirmation-container {
    background-color: $white;
    width: 60.4rem;
    height: 23.2rem;
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .buttons-container {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .button-all-cancel {
    border: 0.15rem solid $website-button-border;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $white;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
  }

  .button-all-remove {
    color: $white;
    border-color: $red;
    background-color: $red;
    border: none;
    box-sizing: border-box;
    border-radius: 0.4rem;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
    margin-left: 1rem;
  }

  .fullscreen-container {
    background: rgba(0, 37, 72, 0.7);
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .title {
    color: $black;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.8rem;
    display: flex;
    align-items: center;
    margin-top: 9.4rem;
    margin-left: 9.6rem;
  }

  .list {
    margin: 0;
    padding: 0;
    justify-items: center;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 1.6rem;
    row-gap: 1.6rem;
    margin-bottom: 10rem;
    @media only screen and (max-width: 75rem) {
      grid-template-columns: repeat(2, auto);
    }
    @media only screen and (max-width: 55rem) {
      grid-template-columns: repeat(1, auto);
    }
    @media only screen and (max-width: 130rem) {
      padding-top: 5rem;
    }
  }

  .no-results-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .no-results-title {
    font-size: 1.6rem;
    align-items: center;
    padding-bottom: 1rem;
  }
}
