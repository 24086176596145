@import "../../../styling/colors";

.sidemenu{
    display: flex;
    flex-direction: column;
    height: 98%;

    &__logo{
      padding-left: 10rem;
      height: 2.2rem;
      margin-top: -3rem;
      position: absolute;
  }

    ul{
      padding: 0;
      list-style-type: none;
      padding-top: 4rem;
      flex: 1;
    }

    .list-item{
      font-size: 1.6rem;
      color: $white;
      font-weight: 400;
      &.active{
        background-color: $sidemenu_active_color;
        font-weight: bold;
      }
    }

    &__item{
      display: inline-block;
      &:focus, &:visited, &:link, &:active {
        color: $white;
        text-decoration: none;
      }
      line-height: 5.5rem;
      height: 5.6rem;
      padding-left: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &__link{
      &:focus, &:hover, &:visited, &:link, &:active {
        color: $white;
        text-decoration: none;
      }
    }

    &__info {
      display: inline-block;
      &:focus, &:visited, &:link, &:active {
        color: $white;
        text-decoration: none;
      }
      line-height: 5.5rem;
      height: 5.6rem;
      padding-left: 3.2rem;
    }

    &__button {
      font-family: HKNova;
      font-weight: bold;
      display: block;
      background-color: $background;
      color: $white;
      height: 3.5rem;
      border-color: $white;
      border: 0.15rem solid $white;
      box-sizing: border-box;
      border-radius: 0.4rem;
      width: 10rem;
      position: relative;
      margin-left: 3.2rem;
      margin-top: 0;
    }
}
