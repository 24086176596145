@import '../../../styling/colors';
@import '../../../styling/fonts';

.filtermenu {
  display: flex;
  flex-direction: column;
  height: 98%;

  &__reset {
    cursor: pointer;
    color: $primary;
    position: absolute;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
    right: 9rem;
    top: 1.8rem;
    text-decoration-line: underline;
  }

  &__filter {
    color: $primary;
    position: absolute;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
    left: 1.6rem;
    top: 1.9rem;
  }

  &__filtercontainer {
    margin-left: 1.6rem;
    margin-top: -0.5rem;
    margin-bottom: 1.8rem;
  }

  &__filtercontainer_small {
    margin-left: 1.6rem;
    margin-bottom: 1.5rem;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__listitem {
    margin-bottom: 0.1rem;
  }

  &__title {
    color: $text_dark_grey;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 0.5rem;
  }

  &__budget {
    display: flex;
  }

  .react-select-container {
    width: 24.7rem;
  }

  .react-select__placeholder {
    color: $filter_placeholder;
  }

  .react-select__menu-list {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $primary;
  }

  .react-select__input input{
    font-family: HKNova;
  }

  .budget_input {
    border: 0.1rem solid $mystic-white;
    box-sizing: border-box;
    border-radius: 0.4rem;
    height: 3.6rem;
    width: 10.8rem;
    font-size: 1.6rem;
    padding-left: 1.2rem;
  }

  .budget_input::placeholder {
    color: $filter_placeholder;
    opacity: 0.2;
  }

  .separator {
    color: $filter_separator;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: 0.7rem;
  }

  .error-budget {
    color: $red;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 0.9rem;
  }
}
