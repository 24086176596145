@import '../../../styling/colors';

.checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $primary;
  margin: 0rem 0.5rem;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $primary;
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create a custom checkbox */
  .checkmark {
    // position: absolute;
    // top: 0;
    // left: 0;
    height: 1.8rem;
    display: inline-block;
    width: 1.8rem;
    margin-right: 1rem;
    background-color: $mystic-white, 50%;
    border: 2px solid $primary;
    box-sizing: border-box;
    border-radius: 0.2rem;
    position: relative;
    margin-bottom: 1.2rem;
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 0rem;
      left: 0.5rem;
      width: 0.5rem;
      height: 1.1rem;
      border: solid $white;
      border-width: 0 0.2rem 0.2rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .checkbox-span {
    margin-bottom: 1rem;
  }

    /* When the checkbox is checked, add a blue background */
    input:indeterminate ~ .checkmark {
      background-color: $primary;
    }
    /* Show the checkmark when checked */
    input:indeterminate ~ .checkmark:after {
      display: block;
      border-width: 0.2rem 0rem 0;
      top: 0.6rem;
      left: 0.3rem;
      width: 0.8rem;
      transform: rotate(0deg);
    }
}
