@import '../../../styling/colors';

.mail-edit {
  .edit-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90rem;
    min-height: 50rem;
    background-color: $white;
    padding-top: 2.6rem;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    padding-bottom: 0.9rem;
    overflow-y: scroll;
    @media only screen and (min-width: 60rem) {
      width: 86rem;
    }
  }

  .edit-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    margin-bottom: 2.8rem;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    background-color: $white;
  }

  //cancel and continue buttons
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-send-cancel {
    border: 0.15rem solid $website-button-border;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $white;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    color: $primary;
    margin-top: 0;
  }

  .button-send-continue {
    color: $white;
    border-color: $primary;
    background-color: $primary;
    border: none;
    box-sizing: border-box;
    border-radius: 0.4rem;
    height: 4rem;
    width: 12rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.0rem;
    margin-left: 0.8rem;
    margin-top: 0;
  }

  .edit-item {
    display: block;
    margin-bottom: 2.8rem;
  }

  .edit-label {
    display: block;
    color: $text_dark_grey;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .input {
    display: block;
    font-size: 1.6rem;
    outline: none;
    background: transparent;
    border: 0.1rem solid $mystic-white;
    border-radius: 0.4rem;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 3.6rem;
    padding-left: 1.2rem;
  }

  .input::placeholder {
    color: $text_light_grey;
  }

  //Attachment
  .attachment {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      height: 3.6rem;
      margin-bottom: 0.5rem;
      max-width: 81rem;
      background-color: $mystic-white;
      border-radius: 4px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 1rem;
    }
    &-title {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-left: 0.9rem;
      margin-right: 0.9rem;
      position: relative;
      top: -0.2rem;
    }

    &-image {
      height: 1.6rem;
      width: 1.6rem;
    }

    &-remove {
      height: 1.6rem;
      width: 1.6rem;
      cursor: pointer;
    }
  }

  .react-select-template {
    width: 18rem;
    font-size: 1.6rem;
    cursor: pointer;
  }

  //Textbox
  .bf-container {
    resize: none;
    max-height: 30rem;
    border: 1px solid #c3c9d3;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .bf-content {
    max-height: 25rem;
  }

  //react-select
  .react-select-container {
    width: 24.7rem;
  }

  .react-select__placeholder {
    color: $filter_placeholder;
  }

  .async-select {
    font-size: 1.6rem;
  }

  .react-select__menu-list {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $primary;
  }

  .error-mail{
    margin-top: 0.5rem;
  }
}
