@import '../../../styling/colors';

.radio-container {
  display: block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1.2rem;
  margin-left: 1.8rem;
  padding-left: 3rem;
  cursor: pointer;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $primary;
}

/* Customize the label (the container) */
.container {
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.2rem;
  border-color: $primary;
  color: $primary;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .radio-input {
  background-color: $white;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .radio-input {
  background-color: $white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-input:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .radio-input:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .radio-input:after {
  top: 0.3rem;
  left: 0.3rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: $primary;
}
