@import '../../../styling/colors';

.new-password-page {
  .title {
    color: $primary;
    font-weight: bold;
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    margin-top: 1.4rem;
  }

  .description {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 3rem;
    line-height: 2.5rem;
  }

  .link {
    font-weight: 600;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      color: $primary;
      text-decoration: none;
    }
  }

  .requirement-list {
    margin-left: -2rem;
    margin-top: 0.5rem;
  }
}
