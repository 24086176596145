@import '../../../styling/colors';
@import '../../../styling/fonts';

.irg-form-item {
  display: block;
  margin: 0 0 1em;
}

.irg-label {
  display: block;
  color: $text_dark_grey;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.irg-input {
  display: block;
  font-size: 1.6rem;
  outline: none;
  background: transparent;
  border: 0.1rem solid $mystic-white;
  border-radius: 0.4rem;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 3.6rem;
  padding-left: 1.2rem;
}

.irg-input::placeholder {
  color: $text_light_grey;
}
