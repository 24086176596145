@import '../../../styling/colors';

.forgot-password-page {
  .title {
    color: $primary;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    margin-top: 1.4rem;
    font-weight: bold;
  }

  .description {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 3rem;
    line-height: 2.5rem;
  }

  .link {
    font-weight: 600;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      color: $primary;
      text-decoration: none;
    }
  }

  .backbutton {
    left: 1rem;
    top: -4rem;
    color: $white;
    font-size: 1.6rem;
    position: absolute;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }

  .backbutton-icon {
    position: relative;
    left: -1rem;
    top: 0.5rem;
  }
}
