// colors
:root {
  --background: #002548;
  --black: #000000;
  --primary: #002548;
  --white: #ffffff;
  --text_light_grey: #a3aab3;
  --text_dark_grey: #666666;
  --sidemenu_active_color: #009ac7;
  --grey_border: #c7c7cc;
  --radial_gradient_background_blue: rgba(0, 154, 199, 0.25) 0%, rgba(0, 154, 199, 0) 60%;
  --radial_gradient_background_purple: rgba(113, 89, 166, 0) 0%, rgba(113, 89, 166, 0) 100%;
  --red: #ff6161;
  --error_background: rgba(255, 97, 97, 0.1);
  --hover_card: rgb(0, 37, 72, 0.7);
  --filter_placeholder: #212121;
  --filter_separator: #3f484f;
  --scrollbar-thumb: #c4c4c4;
  --no-results-border: #8092a3;
  --extra-info-background: #e4e9f088;
  --extra-info-dash: #009ac7;
  --website-button-border: #8092a3;
  --image-shadow: rgba(0, 0, 0, 0.2);
  --scrollbar-thumb: #c4c4c4;
  --no-results-button: #8092a3;
  --mystic-white: #e4e9f0;
  --sort-container-box-shadow: rgba(0, 0, 0, 0.24);
  --toast-succes: #6cc7b4;
}

$background: var(--background);
$black: var(--black);
$primary: var(--primary);
$white: var(--white);
$text_light_grey: var(--text_light_grey);
$text_dark_grey: var(--text_dark_grey);
$grey_border: var(--grey_border);
$radial_gradient_background_blue: var(--radial_gradient_background_blue);
$radial_gradient_background_purple: var(--radial_gradient_background_purple);
$red: var(--red);
$error_background: var(--error_background);
$sidemenu_active_color: var(--sidemenu_active_color);
$hover_card: var(--hover_card);
$filter_placeholder: var(--filter_placeholder);
$filter_separator: var(--filter_separator);
$scrollbar-thumb: var(--scrollbar-thumb);
$no-result-border: var(--no-results-border);
$extra-info-background: var(--extra-info-background);
$extra-info-dash: var(--extra-info-dash);
$website-button-border: var(--website-button-border);
$image-shadow: var(--image-shadow);
$no-results-button: var(--no-results-button);
$sort-container-box-shadow: var(--sort-container-box-shadow);
$mystic-white: var(--mystic-white);
$toast-succes: var(--toast-succes);
