@import '../../../styling/colors';

.overview-page {
	//filter menu
	.menu-filter {
		.bm-burger-button {
			position: fixed;
			width: 0;
			height: 0;
		}

		.bm-burger-bars {
			background: $primary;
		}

		.bm-burger-bars-hover {
			background: $primary;
		}

		.bm-cross-button {
			height: 2.4rem;
			width: 2.4rem;
			position: absolute;
			right: 1.6rem;
			margin-top: 0.9em;
			color: $primary;
		}

		.bm-cross {
			color: $primary;
		}

		.bm-menu-wrap {
			position: fixed;
			height: 100%;
			overflow: hidden;
		}

		.bm-menu {
			background: $mystic-white;
			font-size: 1.6rem;
			padding-top: 6.7rem;
		}

		.bm-item-list {
			color: $white;
			overflow-x: hidden;
		}

		.bm-overlay {
			background-color: transparent !important;
			pointer-events: none;
		}
	}

	//sidemenu menu
	.menu-sidemenu {
		.bm-burger-button {
			position: fixed;
			width: 3.6rem;
			height: 3rem;
			left: 3.2rem;
			margin-top: 3.2rem;
		}

		.bm-burger-bars {
			background: $primary;
		}

		.bm-burger-bars-hover {
			background: $primary;
		}

		.bm-cross-button {
			height: 2.4rem;
			width: 2.4rem;
			position: absolute;
			left: 4.1rem;
			margin-top: 3.3rem;
		}

		.bm-cross {
			background: $primary;
		}

		.bm-menu-wrap {
			position: fixed;
			height: 100%;
			overflow: hidden;
		}

		.bm-menu {
			background: $primary;
			font-size: 1.6rem;
			padding-top: 7.4rem;
		}

		.bm-item-list {
			color: $white;
			overflow: hidden;
		}

		.bm-overlay {
			background: $white;
		}
	}

	//Header
	.header-container {
		background-color: $white;
		width: 100%;
		height: 9.5rem;
		position: fixed;
		z-index: 2;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 9rem;
		padding-right: 4rem;
		transition: all 0.5s ease 0s;

		&.right-open {
			padding-right: 32rem;
		}
	}

	.logo-wrapper {
		display: flex;
		align-items: center;

		.icapps-logo {
			cursor: pointer;
		}

		.env-badge {
			margin-left: 1rem;
			padding: 0.3rem 0.8rem 0.2rem;
			display: inline-block;
			color: white;
			font-weight: bold;
			line-height: 1.3rem;
			font-size: 1.1rem;

			&.dev {
				background-color: #6CC7B4;
			}

			&.staging {
				background: #C3A86B;
			}
		}
	}

	.container {
		display: flex;
		justify-content: center;
	}

	//Filter Button
	.filter-button {
		margin-right: 2rem;
		margin-top: 1rem;
		display: flex;
		cursor: pointer;
		height: 4rem;
		width: 12.5rem;
		align-items: center;

		&.selected {
			background-color: $mystic-white;
		}
	}

	.filter-logo {
		margin-right: 1rem;
		margin-left: 1rem;
		width: 2rem;
		height: 2.2rem;
	}

	.filter-text {
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	.filter-applied {
		border-radius: 50%;
		background-color: $primary;
		font-weight: bold;
		font-size: 1.2rem;
		height: 2.5rem;
		width: 2.5rem;
		color: $white;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-left: 1rem;
	}

	//Sort button
	.sort-button {
		background: none;
		border: none;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 2.4rem;
		height: 4rem;
		width: 9rem;
		margin-top: 1rem;
		display: flex;
		align-items: center;

		&.selected {
			background: $mystic-white;
		}
	}

	.sort-logo {
		margin-left: 1rem;
		color: $primary;
		margin-right: 1rem;
		height: 2.4rem;
		width: 2.4rem;
		display: flex;
	}

	.sort-container {
		position: absolute;
		border-radius: 0.4rem;
		height: 19.2rem;
		width: 28rem;
		right: 0;
		top: 5.5rem;
		box-shadow: 0.1rem 0.2rem 0.8rem $sort-container-box-shadow;
		background-color: $white;
		z-index: 1;
	}

	.sort-sortby-text {
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: $text_dark_grey;
		margin-left: 1.6rem;
		margin-top: 1.6rem;
		margin-bottom: 1.4rem;
	}


	.page-wrap {
		min-height: 100vh;
	}

	.loading-div {
		width: 36rem;
		height: 22.4rem;
		margin-bottom: 4.2rem;
		background-color: $mystic-white;
		box-sizing: border-box;
	}

	.list {
		margin: 0;
		margin-bottom: 10rem;
		padding: 0;
		padding-top: 10rem;
		justify-items: center;
		align-items: center;
		justify-content: center;
		display: grid;
		grid-template-columns: repeat(3, auto);
		column-gap: 1.6rem;
		row-gap: 1.6rem;
		@media only screen and (max-width: 75rem) {
			grid-template-columns: repeat(2, auto);
		}
		@media only screen and (max-width: 55rem) {
			grid-template-columns: repeat(1, auto);
		}
	}

	.no-results-container {
		min-height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		transition: all 0.5s ease 0s;

		&.right-open {
			padding-right: 32rem;
		}
	}

	.no-results-title {
		font-size: 1.6rem;
		align-items: center;
		padding-bottom: 2rem;
	}

	.no-results-button {
		display: block;
		background-color: $white;
		color: $primary;
		font-weight: bold;
		font-size: 1.6rem;
		height: 4rem;
		width: 12rem;
		border: 0.15rem solid $no-results-button;
		box-sizing: border-box;
		border-radius: 0.4rem;
		position: relative;
	}
}

::-webkit-scrollbar {
	width: 0.6rem;
}

::-webkit-scrollbar-track {
	background-color: $mystic-white;
}

::-webkit-scrollbar-thumb {
	border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb {
	background-color: $scrollbar-thumb;
}
