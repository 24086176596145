@import '../../../styling/colors';
@import '../../../styling/fonts';

.image{
    width: 36rem;
    height: 22.4rem;
    border: 0.1rem solid $mystic-white;
    box-sizing: border-box;
}

.grid-container {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
      "platform platform"
      "technology technology"
      "sector sector"
      "budget care"
      "team type"
      "accountManager .";
  }

  .platform { grid-area: platform; }

  .technology { grid-area: technology; }

  .sector { grid-area: sector; }

  .budget { grid-area: budget; }

  .care { grid-area: care; }

  .team { grid-area: team; }

  .type { grid-area: type; }

  .accountManager { grid-area: accountManager; }

.hover-container{
    position: relative;
    > div{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97%;
        padding-top: 0.6rem;
        &:hover, &:focus{
            list-style-type: none;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 3.5rem;
            color: $white;
            background-color: $hover_card;
            opacity: 1;
        }
    }
}

.list-item{
    color: $primary;
    list-style-type: none;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.link{
    &:focus, &:hover, &:visited, &:link, &:active {
      color: $primary;
      text-decoration: none;
    }
}

.project-title{
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 3.4rem;
    margin-left: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 34rem;
    display: block;
    overflow: hidden
}
