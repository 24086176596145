@import '../../../styling/colors';
@import '../../../styling/fonts';

.searchbar {
  justify-content: center;
  margin-left: 8rem;
  display: flex;
  flex-direction: row-reverse;
  .search {
    outline: 0;
    border-width: 0 0 0.1rem;
    border-color: $text_light_grey;
    width: 28.2rem;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    &::placeholder {
      color: $text_light_grey;
    }
    &:focus{
      border-color: $primary;
      & ~ .search-logo{
        color: $primary;
      }
      webkit-input-placeholder {
        color: $primary;
      }

      &::placeholder {
        color: $primary;
      }
    }
  }

  .search-logo {
    color: $text_light_grey;
    margin-top: 0.8rem;
    margin-right: 1.1rem;
    svg {
      overflow: visible;
      path,
      circle {
        stroke: currentColor;
      }
    }
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50em;
  background: url('../../_assets/_icons/cancelsearch.svg');
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}
