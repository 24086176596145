@import '../../../styling/colors';

.loading-box {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  &::before {
    content: '';
    position: absolute;
    border: 0.5rem solid $primary;
    border-radius: 10rem;
    width: 5rem;
    height: 5rem;
    border-bottom-color: transparent;
    animation: animate-inner 1.4s linear infinite;
  }
}

@keyframes animate-inner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
