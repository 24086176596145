@import '../../../styling/colors';

.unauth-layout {
  background: radial-gradient(circle at top left, $radial_gradient_background_blue),
    radial-gradient(circle at bottom right, $radial_gradient_background_purple);
  background-color: $background;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-view {
  position: relative;
  background-color: $white;
  width: 100%;
  @media only screen and (min-width: 4rem) {
    width: 60%;
    max-width: 38.4rem;
  }

  &-image {
    display: block;
    margin: 0 auto;
    width: 55%;
    height: auto;
  }

  &-logo {
    margin-top: 4rem;
    margin-bottom: 3.5rem;
    display: block;
  }
  &-contents {
    padding: 2rem 3.2rem;
    border: 0.1rem solid $grey_border;
    box-sizing: border-box;
  }

  &-label {
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 2.8rem;
    margin-bottom: 1rem;
  }
}
