// Fonts

@font-face{
  font-family: HKNova;
  src: url("../src/fonts/HKNova-Regular.ttf");
  font-weight: 400;
}
@font-face{
  font-family: HKNova;
  src: url("../src/fonts/HKNova-Bold.ttf");
  font-weight: bold;
}
@font-face{
  font-family: HKNova;
  src: url("../src/fonts/HKNova-Medium.ttf");
  font-weight: 500;
}
