@import '../../../styling/colors';

.mail {
  background: rgba(0, 37, 72, 0.7);
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
