@import '../styling/fonts';
@import '../styling/colors';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: auto;
  margin: 0;
  font-family: HKNova, sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
}

button {
  font-family: HKNova, sans-serif;
  cursor: pointer;
}

input{
  font-family: HKNova, sans-serif;
}

textarea {
  font-family: HKNova;
}

.Toastify__toast-body {
  font-size: 1.6rem;
  font-family: HKNova;
}

.Toastify__toast--success {
  background:$toast-succes;
}
